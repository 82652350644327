import { PageState } from "lib/constants";
import useDarkMode from "lib/hooks/useDarkMode";
import { useEffect, useState } from "react";

import {
  FaAt,
  FaClipboardList,
  FaCode,
  FaGamepad,
  FaMoon,
  FaSun,
  FaUserAlt,
} from "react-icons/fa";
import "styles/navbar.css";
interface IProps {
  page: PageState;
  setPage: (page: PageState) => void;
}

export default function Navigator(props: IProps) {
  const { page, setPage } = props;

  const pageData = [
    {
      id: "about",
      page: PageState.About,
      icon: <FaUserAlt size="28" />,
      tooltip: "About Me",
    },
    {
      id: "resume",
      page: PageState.Resume,
      icon: <FaClipboardList size="28" />,
      tooltip: "Resume",
    },
    {
      id: "projects",
      page: PageState.Projects,
      icon: <FaCode size="28" />,
      tooltip: "Projects",
    },
    // {
    //   id: "games",
    //   page: PageState.Games,
    //   icon: <FaGamepad size="28" />,
    //   tooltip: "Games",
    // },
    {
      id: "contact",
      page: PageState.Contact,
      icon: <FaAt size="24" />,
      tooltip: "Contact Me",
    },
  ];

  // window.addEventListener("scroll", () => {
  //   let current = "";
  //   sections.forEach((section) => {
  //     const sectionTop = section.offsetTop;
  //     const sectionHeight = section.clientHeight;
  //     if (pageYOffset >= sectionTop - sectionHeight / 3) {
  //       current = section.getAttribute("id") as string;
  //     }
  //   });

  //   navLi.forEach((li) => {
  //     li.classList.remove("active");
  //     if (li.classList.contains(current)) {
  //       li.classList.add("active");
  //     }
  //   });
  // });
  const [currentSection, setCurrentSection] = useState<string>("about");

  useEffect(() => {
    function handleScroll() {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollY >= sectionTop - sectionHeight / 2) {
          setCurrentSection(section.getAttribute("id") ?? "about");
        }
      });
    }

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    // Remove the listener as soon as the component is unmounted
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);

  return (
    <>
      <nav
        className="sticky top-0 sm:top-16 left-0 mt-0 sm:mt-16 w-full sm:w-16 sm:flex-col h-fit hidden xl:flex px-2
                  bg-white dark:bg-gray-900 shadow-lg py-3 sm:pt-4 sm:pb-3 sm:rounded-lg justify-between items-center z-50"
      >
        {pageData.map((item, index) => {
          return (
            <div
              key={`nav-${index}`}
              className={`sidebar-icon group hidden lg:block ${
                page === item.page && "active"
              }`}
              onClick={() => {
                setPage(item.page);
                // document
                //   .getElementById(item.id)
                //   ?.scrollIntoView({ block: "start" });
              }}
            >
              {item.icon}
              <span className="sidebar-tooltip xl:group-hover:scale-100">
                {item.tooltip}
              </span>
            </div>
          );
        })}
        <div className="flex w-auto sm:w-full justify-center sm:mt-4 sm:mb-2">
          <ThemeIcon />
        </div>
      </nav>
      <nav
        className="sticky top-0 sm:top-16 left-0 mt-0 sm:mt-16 w-full sm:w-16 flex sm:flex-col xl:hidden h-fit 
                  bg-white dark:bg-gray-900 shadow-lg py-3 sm:pt-4 sm:pb-3 sm:rounded-lg justify-between items-center z-50"
      >
        {pageData.map((item, index) => {
          return (
            <div
              key={`nav-${index}-sm`}
              className={`sidebar-icon group hidden ${
                currentSection === item.id && "active"
              }`}
              onClick={() => {
                // setPage(item.page);
                document
                  .getElementById(item.id)
                  ?.scrollIntoView({ block: "start", inline: "start" });
              }}
            >
              {item.icon}
              <span className="sidebar-tooltip xl:group-hover:scale-100">
                {item.tooltip}
              </span>
            </div>
          );
        })}
        <div className="flex w-auto sm:w-full justify-center sm:mt-4 sm:mb-2">
          <ThemeIcon />
        </div>
      </nav>
    </>
  );
}

const ThemeIcon = () => {
  const [darkTheme, setDarkTheme] = useDarkMode();
  const handleMode = () => setDarkTheme(!darkTheme);
  return (
    <span onClick={handleMode}>
      {darkTheme ? (
        <FaSun size="24" className="top-navigation-icon" />
      ) : (
        <FaMoon size="24" className="top-navigation-icon" />
      )}
    </span>
  );
};

// function useOnScreen() {
//   return currentSection;
// }

import { PageState, socials } from "lib/constants";
import { Holder } from "./holder";
import { useState } from "react";
import { Button, Loader } from "components/common";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
interface IProps {
  setPage: (pageState: PageState) => void;
}

export function Contact(props: IProps) {
  return <Holder id="contact" child={<Page />} />;
}

function Page() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoader] = useState(false);
  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log(e.target);
    setLoader(true);
    emailjs
      .sendForm("aastiktest", "contact_template", e.target, "wjZAlL0adxwi8tcKS")
      .then(
        () => {
          // console.log(result.text);
          toast.success(
            "Your contact form has been sent Successfully. Aastik will reply ASAP."
          );
          setLoader(false);
          setName("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          // console.log(error.text);
          console.log(error);
          toast.error(
            "Your contact form could not be sent to Aastik. Please try again later."
          );

          setLoader(false);
        }
      );
  };
  return (
    <div className="flex w-full px-6 text-black dark:text-white flex-col justify-center py-10 xl:py-0">
      <h2 className="text-3xl">Contact Me</h2>
      <hr className="bg-gray-500 my-1" />
      <div className="w-full">
        <div className="flex w-full justify-between text-lg my-2">
          <div>Email</div>
          <a
            href="mailto:saini.aastik@gmail.com"
            data-rel="external"
            className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-xl hover:text-white cursor-pointer"
          >
            saini.aastik@gmail.com
          </a>
        </div>
        <hr />
        <div className="flex w-full justify-between text-lg my-2">
          <div>Phone</div>
          <a
            href="tel:+918572825599"
            data-rel="external"
            className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-xl hover:text-white cursor-pointer"
          >
            +91 85728 25599
          </a>
        </div>
        <hr />
        {/* <div className="text-2xl mt-10"> Socials </div>
        <hr /> */}
        <div className="flex w-full mt-6 items-center flex-wrap justify-end gap-4 px-3">
          {socials.map((item, index) => {
            return (
              <a
                aria-label={`social-${index}`}
                key={`social-${index}`}
                href={item.url}
                target="_blank"
              >
                {item.icon}
              </a>
            );
          })}
        </div>
        <div className="text-3xl mt-10"> Contact using a Form </div>
        <hr />

        <form
          className="w-full flex flex-wrap justify-center mt-3"
          onSubmit={(e) => submitHandler(e)}
        >
          <div className="mb-4 w-full sm:w-1/2 px-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              required
              disabled={isLoading}
              value={Name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </div>
          <div className="mb-4 w-full sm:w-1/2 px-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              name="email"
              required
              disabled={isLoading}
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
            />
          </div>
          <div className="mb-4 w-full px-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              rows={5}
              disabled={isLoading}
              required
              placeholder="Message"
              value={Message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="flex w-full items-center justify-end">
            {isLoading ? <Loader /> : <></>}
            <Button
              children={<>Submit</>}
              type="primary"
              isDisabled={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

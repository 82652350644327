import {
  ButtonSize,
  EnabledButtonType,
  DisabledButtonType,
} from "lib/themes/button";
import { Spinner } from "components/common";

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  onClick?: (() => void) | undefined;
  className?: string;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "primary" | "secondary" | "tertiary";
  isLoading?: boolean;
};

function Button({
  children,
  onClick,
  className = "",
  isDisabled = false,
  size = "md",
  type = "primary",
  isLoading,
}: Props) {
  const classNames = `${
    isDisabled ? DisabledButtonType[type] : EnabledButtonType[type]
  } ${ButtonSize[size]} ${className} flex items-center justify-center`;

  return (
    <button onClick={onClick} disabled={isDisabled} className={classNames}>
      {isLoading && <Spinner size={size} className="mr-2" />}
      {children}
    </button>
  );
}

export default Button;

import App from "pages/App";
import { Breakout, Dino, Snake } from "pages/games";
import { Navigate, createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  // {
  //   path: "/dino",
  //   element: <Dino />,
  // },
  // {
  //   path: "/breakout",
  //   element: <Breakout />,
  // },
  // {
  //   path: "/snake",
  //   element: <Snake />,
  // },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

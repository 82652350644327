import { FaEnvelope, FaGithub, FaLinkedinIn, FaSpotify } from "react-icons/fa";

export const languages = [
  {
    name: "TypeScript",
    src: "/languages/typescript-min.png",
  },
  {
    name: "Java",
    src: "/languages/java-min.png",
  },
  {
    name: "Python",
    src: "/languages/python-min.png",
  },
  {
    name: "C#",
    src: "/languages/c-sharp-min.png",
  },
];

export const frameworks = [
  {
    name: "ReactJS",
    src: "/languages/reactjs-min.png",
  },
  {
    name: "NextJS",
    src: "/languages/nextjs-min.png",
  },
  {
    name: "NodeJs",
    src: "/languages/node.svg",
  },

  {
    name: "Android",
    src: "/languages/android-min.png",
  },
  {
    name: "Spring Boot",
    src: "/languages/spring-min.png",
  },
  {
    name: "Kotlin",
    src: "/languages/kotlin-min.png",
  },

  {
    name: ".NET",
    src: "/languages/net-min.png",
  },
  {
    name: "Unity",
    src: "/languages/unity-min.png",
  },
  {
    name: "Windows",
    src: "/languages/windows-min.png",
  },

  {
    name: "Django",
    src: "/languages/django-min.png",
  },
  {
    name: "TensorFlow",
    src: "/languages/tensorflow-min.png",
  },
  {
    name: "Keras",
    src: "/languages/keras-min.png",
  },
];

export const socials = [
  {
    icon: <FaLinkedinIn size="32" className="hover:text-blue-500" />,
    url: "https://linkedin.com/in/aastik-saini",
  },
  {
    icon: <FaGithub size="32" className="hover:text-violet-500" />,
    url: "https://github.com/DMR-8",
  },
  {
    icon: <FaSpotify size="32" className="hover:text-green-500" />,
    url: "https://open.spotify.com/user/aastikkaloti",
  },
  {
    icon: <FaEnvelope size="32" className="hover:text-blue-500" />,
    url: "mailto:saini.aastik@gmail.com",
  },
];

import { resume_content } from "lib/constants";
import { Holder } from "./holder";

export function Resume() {
  return <Holder id="resume" child={<Page />} />;
}

function Page() {
  return (
    <div className="flex w-full px-6 text-black dark:text-white flex-col justify-center xl:mb-0 mb-10">
      <h2 className="text-3xl sm:text-4xl mt-10 ">Work Experience</h2>
      <hr className="bg-gray-500 my-1" />

      <div className="w-full flex justify-center mt-5">
        <div className="flex w-11/12 flex-wrap items-start justify-center">
          {resume_content.map((item) => {
            return (
              <div
                key={`${item.company_name}-holder`}
                className="w-full sm:w-1/2 my-2"
              >
                <div className="bg-purple-200 rounded-2xl dark dark:bg-purple-600 flex items-center px-2 text-sm h-fit w-44 text-center justify-center mb-2">
                  {item.tenure}
                </div>
                <img
                  src={`${item.logo}`}
                  className="h-12 w-32 bg-white rounded-lg"
                  alt={`${item.company_name}-logo`}
                />
                <h3 className="text-2xl font-bold">{item.title}</h3>
                <hr className="w-11/12" />
                <div className=" mt-2 mb-3 w-11/12">{item.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

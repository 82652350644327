import { motion } from "framer-motion";
import useWindowDimensions from "lib/core/util";

export interface IProps {
  id: string;
  child: JSX.Element;
}

const largeVariants = {
  offscreen: {
    opacity: 0,
    x: 100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transisition: {
      type: "tween",
      bounce: 0.4,
      duration: 6,
    },
  },
};

const smallVariants = {
  offscreen: {
    opacity: 0,
    y: 0,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transisition: {
      type: "tween",
      bounce: 0.4,
      duration: 0.5,
    },
  },
};

export function Holder(props: IProps) {
  const { id, child } = props;
  const windowDimensions = useWindowDimensions();

  return (
    <motion.section
      id={id}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
      variants={windowDimensions.width > 1280 ? largeVariants : {}}
      className="flex flex-col justify-center items-center  xl:w-7/12 xl:min-w-7/12 xl:h-5/6 xl:max-h-5/6 sm:px-0 scroll-mt-24 mb-1 xl:my-8 w-full lg:rounded-xl bg-white dark:bg-gray-700 drop-shadow-lg"
    >
      {/* <div

        className="flex grow items-center justify-center  "
      > */}
      {child}
      {/* </motion.div> */}
    </motion.section>
  );
}

export const EnabledButtonType = {
  primary:
    "bg-violet-600 hover:bg-violet-700 text-white font-semibold shadow-md transition delay-100 ease-in-out",
  secondary:
    "bg-white hover:bg-violet-600 hover:text-white text-violet-600 font-semibold transition delay-100 ease-in-out border-2 border-violet-600 shadow-md",
  tertiary:
    "bg-white hover:underline text-violet-600 font-semibold transition delay-100 ease-in-out",
};

export const DisabledButtonType = {
  primary: "bg-slate-400 text-white font-semibold shadow-md",
  secondary:
    "bg-white text-slate-400 font-semibold border-2 border-slate-400 shadow-md",
  tertiary: "bg-white text-slate-400 font-semibold",
};

export const ButtonSize = {
  sm: "py-1 px-4 text-base rounded-lg",
  md: "py-2 px-6 text-lg rounded-3xl",
  lg: "py-3 px-8 text-xl rounded-xl",
};

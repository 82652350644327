import React from "react";
import ReactDOM from "react-dom/client";
import "styles/index.css";
import "react-toastify/dist/ReactToastify.css";

import reportWebVitals from "./reportWebVitals";
import { Router } from "lib/routes";

import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const firebaseConfig = {
  apiKey: "AIzaSyCgSHImsO0Us0IMxVCM1Zf_dvodqn90g4M",
  authDomain: "aastik-99.firebaseapp.com",
  projectId: "aastik-99",
  storageBucket: "aastik-99.appspot.com",
  messagingSenderId: "203902644069",
  appId: "1:203902644069:web:7491ea5427a3fd0acefb8c",
  measurementId: "G-5SST1RWYZ3",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === "development") reportWebVitals(console.log);

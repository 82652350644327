import { SpinnerSize } from "lib/themes";

type Props = {
  size?: "sm" | "md" | "lg";
  className?: string;
};

const Spinner = ({ size = "md", className = "" }: Props) => {
  const classNames = `animate-spin inline ${SpinnerSize[size]} ${className}`;
  return (
    <svg className={classNames} viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        fill="none"
      />
      <path
        className="opacity-50"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
      ></path>
    </svg>
  );
};

export default Spinner;

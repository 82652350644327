import {
  BiLogoVuejs,
  BiLogoReact,
  BiLogoGoLang,
  BiLogoAndroid,
  BiLogoAngular,
  BiLogoFlutter,
  BiLogoPython,
} from "react-icons/bi";

export const projects = [
  {
    title: "URL Shortener",
    desc: `A URL Shortener project with in-memory cache`,
    link: "https://github.com/DMR-8/url-shortener",
    technology: <BiLogoVuejs size="50" className="text-green-600" />,
  },
  {
    title: "Theme Changer",
    desc: `A Flutter App that shows usage of Shared Preferences`,
    link: "https://github.com/DMR-8/color-changer",
    technology: <BiLogoFlutter size="50" className="text-blue-400" />,
  },
  {
    title: "YouTube Clone",
    desc: `A YouTube clone made using Expo and React-Native`,
    link: "https://github.com/DMR-8/youtube-clone-expo",
    technology: <BiLogoReact size="50" className="text-cyan-400" />,
  },
  {
    title: "Flappy Bird",
    desc: `Flappy Bird game clone made using pygame2 library`,
    link: "https://github.com/DMR-8/flappy-bird",
    technology: <BiLogoPython size="50" className="text-yellow-400" />,
  },
  {
    title: "BlockChain Poll",
    desc: `A blockchain-based polling app made using Angular and Solidity`,
    link: "https://github.com/DMR-8/blockchain-poll",
    technology: <BiLogoAngular size="50" className="text-red-600" />,
  },
  {
    title: "Go Starter",
    desc: `Dump of Go Learning Files. All Basics covered.`,
    link: "https://github.com/DMR-8/go-starter",
    technology: <BiLogoGoLang size="50" className="text-cyan-500" />,
  },
  {
    title: "React Sudoku",
    desc: `Sudoku Game made using React with Typescript + Redux.`,
    link: "https://github.com/DMR-8/react-sudoku",
    technology: <BiLogoReact size="50" className="text-cyan-400" />,
  },
  {
    title: "Donate Life MIT",
    desc: "Android App to maintain resources in Manipal Blood Bank",
    link: "https://github.com/DMR-8/donate-life-mit",
    technology: <BiLogoAndroid size="50" className="text-lime-500" />,
  },
];

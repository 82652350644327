// import React from "react";
// import logo from "./logo.svg";
// import { Logo } from "components/common";

import { Navigator } from "components/common";
import { Projects, About, Resume, Contact, Games } from "components/home";
import { MainHolder } from "components/home/mainHolder";
import { PageState } from "lib/constants";
import useWindowDimensions from "lib/core/util";
import { useState } from "react";

// import "styles/App.css";
function App() {
  const [pageState, setPageState] = useState<PageState>(PageState.About);
  const dimensions = useWindowDimensions();

  return (
    <div
      className="
                w-full
                min-h-screen
                h-full
                bg-gradient-to-b
                to-purple-100
                from-purple-400
                dark:to-purple-800
                flex 
                justify-center
              "
    >
      <div className="ak-container flex flex-col sm:flex-row sm:flex-nowrap min-h-screen">
        {/* <header className="App-header">
        <Logo />
        <h1 className="text-3xl font-bold underline">Aastik Saini</h1>
      </header> */}
        <Navigator page={pageState} setPage={setPageState} />
        {dimensions.width >= 1280 ? (
          <div className="flex grow min-h-screen items-start ">
            <MainHolder />
            {pageState === PageState.About && <About />}
            {pageState === PageState.Projects && <Projects />}
            {pageState === PageState.Resume && <Resume />}
            {/* {pageState === PageState.Games && <Games />} */}
            {pageState === PageState.Contact && (
              <Contact setPage={setPageState} />
            )}
          </div>
        ) : (
          <div className="flex grow flex-wrap">
            <MainHolder />
            <About />
            <Resume />
            <Projects />
            <Contact setPage={setPageState} />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
